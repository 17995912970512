import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Virtual Consultation Room",
    moduleComponentName: "VirtualConsultationRoom",
    moduleProps: {
      name: "virtual-consultation-room",
      scenes: [
        {
          id: "airport",
          disclaimer:
            "Airport terminal is shown for illustration purposes only.",
          geometries: [
            {
              tileSize: 256,
              size: 256,
              fallbackOnly: true,
            },
            {
              tileSize: 512,
              size: 512,
            },
            {
              tileSize: 512,
              size: 1024,
            },
          ],
          limiter: {
            maxResolution: 1024,
            maxVFov: (100 * Math.PI) / 180,
            maxHFov: (120 * Math.PI) / 180,
          },
          viewParams: {
            yaw: 1.734722694106054,
            pitch: 0.03362175356190278,
            fov: 1.5707963267948966,
          },
          hotspots: [
            {
              elementId: "images",
              position: {
                yaw: -0.4858083954281458,
                pitch: 0.01218718593539414,
              },
            },
            {
              elementId: "videos",
              position: {
                yaw: 0.46113816217294357,
                pitch: 0.010183810788282344,
              },
            },
            {
              elementId: "connecting-greater-western-sydney",
              position: { yaw: 1.3547510596530152, pitch: 0.00957126770871497 },
            },
            {
              elementId: "map-link",
              position: {
                yaw: -0.00021480649942695607,
                pitch: 0.4711056527488431,
              },
            },
            {
              elementId: "project-documents-eis",
              position: { yaw: 0.9165335583132261, pitch: 0.29477681154409474 },
            },
            {
              elementId: "project-overview-book",
              position: {
                yaw: -0.9989511241164273,
                pitch: 0.25417751476521033,
              },
            },
            {
              elementId: "project-overview",
              position: {
                yaw: 1.1711538945315247,
                pitch: 0.008341114760803237,
              },
            },
            {
              elementId: "sydney-metro-network-map",
              position: {
                yaw: 1.5562678635352851,
                pitch: 0.009253450982667388,
              },
            },
            {
              elementId: "register-for-email-updates",
              position: {
                yaw: 0.00097129687092945,
                pitch: 0.10323436071285386,
              },
            },
            {
              elementId: "about-the-environmental-impact-statement",
              position: {
                yaw: -1.9392984480287296,
                pitch: 0.09087745830110272,
              },
            },
            {
              elementId: "the-need-for-sydney-metro-wsa",
              position: { yaw: 1.9489914731045257, pitch: 0.07488210918308269 },
            },
            {
              elementId: "claremont-meadows-services-facility",
              position: {
                yaw: -1.5604000128191444,
                pitch: 0.00805156511119165,
              },
            },
            {
              elementId: "bringelly-services-facility",
              position: {
                yaw: -1.1751391112887895,
                pitch: 0.005225921411531331,
              },
            },
            {
              elementId: "stabling-and-maintenance-facility",
              position: { yaw: -1.357606533892504, pitch: 0.07108228182170961 },
            },
            {
              elementId: "staff-1",
              position: { yaw: 1.720757018496136, pitch: 0.1940238217747634 },
            },
            {
              elementId: "st-marys-station",
              position: { yaw: 2.1082876833180197, pitch: 0.11323819920077938 },
            },
            {
              elementId: "orchard-hills-station",
              position: { yaw: 2.3538681466005427, pitch: 0.15886015759101468 },
            },
            {
              elementId: "luddenham-station",
              position: { yaw: 2.811063979569166, pitch: 0.21178351718680233 },
            },
            {
              elementId: "airport-business-park-station",
              position: {
                yaw: -2.8092113224857442,
                pitch: 0.22155442908211853,
              },
            },
            {
              elementId: "airport-terminal-station",
              position: { yaw: -2.338660919216478, pitch: 0.1637662556755597 },
            },
            {
              elementId: "aerotropolis-station",
              position: {
                yaw: -2.1136721980942497,
                pitch: 0.11670478442878007,
              },
            },
            {
              elementId: "building-new-metro-rail",
              position: {
                yaw: -0.8006935454116721,
                pitch: -0.04084902124520973,
              },
            },
            {
              elementId: "luddenham-station-virtual-reality",
              position: {
                yaw: -0.00454282371506487,
                pitch: -0.06928620748628767,
              },
            },
            {
              elementId: "new-railway-for-greater-western-sydney",
              position: {
                yaw: 0.799058293598172,
                pitch: -0.037803907023667094,
              },
            },
          ],
        },
      ],
    },
    rowProps: { noGutters: true },
    columnProps: { className: "virtual-consultation-room-wrapper" },
  },
  {
    name: "VCR First Time Modal",
    moduleComponentName: "FirstTimeModal",
    moduleProps: {
      pageName: "virtual-information-room",
      targetModalId: "vcr-first-start",
    },
  },
];

const VirtualConsultationPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default VirtualConsultationPage;
